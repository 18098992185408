// Generated by Framer (716dd6f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {VMuixt1Qo: {hover: true}};

const cycleOrder = ["VMuixt1Qo"];

const variantClassNames = {VMuixt1Qo: "framer-v-sdkmq5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};


function toResponsiveImage_194x2gw(value) {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const BASE62 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"

function useRandomID(){const ref = React.useRef(null);
if (ref.current === null) {
ref.current = Array(5).fill(0).map(() => BASE62[Math.floor(Math.random() * BASE62.length)]).join("");
}
return ref.current;}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; image?: {src: string; srcSet?: string} }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style: externalStyle = {}, className, width, height, layoutId, variant: outerVariant = "VMuixt1Qo", image: rTW17miHL = {src: new URL("assets/hO09r8kbVUTBlWrSbTVf6P4OM.jpg", import.meta.url).href, srcSet: `${new URL("assets/512/hO09r8kbVUTBlWrSbTVf6P4OM.jpg", import.meta.url).href} 63w, ${new URL("assets/1024/hO09r8kbVUTBlWrSbTVf6P4OM.jpg", import.meta.url).href} 126w, ${new URL("assets/2048/hO09r8kbVUTBlWrSbTVf6P4OM.jpg", import.meta.url).href} 252w, ${new URL("assets/4096/hO09r8kbVUTBlWrSbTVf6P4OM.jpg", import.meta.url).href} 505w, ${new URL("assets/hO09r8kbVUTBlWrSbTVf6P4OM.jpg", import.meta.url).href} 750w`}, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "VMuixt1Qo", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = useRandomID()

const { pointerEvents, ...style } = externalStyle

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div data-framer-generated initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-lVicM", classNames)} style={{display: "contents", pointerEvents: pointerEvents ?? "auto"}}>
<motion.div {...restProps} className={cx("framer-sdkmq5", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"VMuixt1Qo"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"VMuixt1Qo-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><Image background={{alt: "", fit: "fit", intrinsicHeight: 613, intrinsicWidth: 1000, pixelHeight: 613, pixelWidth: 1000, ...toResponsiveImage_194x2gw(rTW17miHL)}} className={"framer-1nqf2hn"} data-framer-name={"image 20"} layoutDependency={layoutDependency} layoutId={"KzcKYJKTp"} style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, filter: "grayscale(1)", WebkitFilter: "grayscale(1)"}} transition={transition} variants={{"VMuixt1Qo-hover": {filter: "grayscale(0)", WebkitFilter: "grayscale(0)"}}}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-lVicM [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-lVicM * { box-sizing: border-box; }", ".framer-lVicM .framer-1u843tk { display: block; }", ".framer-lVicM .framer-sdkmq5 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 4500px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 565px; }", ".framer-lVicM .framer-1nqf2hn { aspect-ratio: 0.12555555555555556 / 1; flex: 1 0 0px; height: var(--framer-aspect-ratio-supported, 4500px); overflow: hidden; position: relative; width: 1px; will-change: transform; }", ".framer-lVicM .framer-v-sdkmq5 .framer-sdkmq5 { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-lVicM .framer-sdkmq5 { gap: 0px; } .framer-lVicM .framer-sdkmq5 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-lVicM .framer-sdkmq5 > :first-child { margin-left: 0px; } .framer-lVicM .framer-sdkmq5 > :last-child { margin-right: 0px; } }", ".framer-lVicM.framer-v-sdkmq5.hover .framer-sdkmq5 { aspect-ratio: 0.12555555555555556 / 1; height: var(--framer-aspect-ratio-supported, 4500px); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 4500
 * @framerIntrinsicWidth 565
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"vR_ueVO25":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"rTW17miHL":"image"}
 */
const FramerUrwASH1WB: React.ComponentType<Props> = withCSS(Component, css) as typeof Component;
export default FramerUrwASH1WB;

FramerUrwASH1WB.displayName = "gender-divide";

FramerUrwASH1WB.defaultProps = {height: 4500, width: 565};

addPropertyControls(FramerUrwASH1WB, {rTW17miHL: {__defaultAssetReference: "data:framer/asset-reference,hO09r8kbVUTBlWrSbTVf6P4OM.jpg?originalFilename=image.jpg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerUrwASH1WB, [])